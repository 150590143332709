import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './locales/en/en.json'

export const languageRes = {
    en: { translation: en },
}


i18n.use(initReactI18next).init({
    compatibiliyJSON: "v3", 
    lng: "en",
    fallbackLng: "en",
    lazy:true,
    resources: languageRes,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});


export default i18n;