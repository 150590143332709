import { memo, useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";

import './css/QuizSend.css'
import manSrc from '../../images/moe.png'

import { Config } from '../../inc/Config'
import { AppContext } from "../../Context";
import Popups from "../../components/Popups";

import Fetch, { Loader, dateToText } from "../../inc/Fetch";
import { Input, MultiSelect } from "../../components/Form";

import { MdDeleteOutline } from "react-icons/md";

import { Question } from "../../components/Elements";

export default memo(function SendTasks({setopen, open}) {
    const { t } = useTranslation();
    

    let myStatus = [
        {
            name: "Active",
            val:1,
        },
        {
            name: "Blocked",
            val:0,
        }
    ]
    
    const active = useRef(true);
    const {success, userinfo} = useContext(AppContext);
    const [suc, setsuc] = success;
    const [logedIn, setlogedIn] = userinfo;
    
    const [miniTabs, setminiTabs] = useState(open?.tab || 0);

    const [values, setvalues] = useState({});
    const [stats, setstats] = useState({});
    const [avg, setavg] = useState({});
    const [loader, setloader] = useState(false);

    const [ploader, setploader] = useState(true);

    const [myClasses, setmyClasses] = useState([]);



    function submit(e) {
        e.preventDefault();
        setloader(true);

        Fetch("students/update", (d) => {

            if (d?.error) {
                setsuc({
                    title:"Ops!!",
                    short:t("error",  { returnObjects: true })[d?.error],
                    error:true
                });
            } else {
                setsuc({
                    title:"Wow!",
                    short:"The Student was updated successfully.",
                    error:false,
                });

                setTimeout(() => {
                    setopen(false);
                }, 2500);


            }

            setloader(false);
        }, values);

    }




    useEffect(() => {
        let Cls = [];
        // Object.entries(global.Classes)?.map(v => {

        //     Object.entries(global.Grades)?.map(v2 => {
        //         Cls.push({name: `${v[1]} (${v2[1]})`, val:`${v[0]}-${v2[0]}`});
        //     });

        // });

        logedIn?.userinfo?.classes?.map(v => {
            Cls.push({ name: `${global.Classes[v[0]]} (${global.Grades[v[2]]})`, val:v });
        });

        setmyClasses(Cls);

        Fetch("students/view_stats", (d) => {

            if (d?.error) {
                setsuc({
                    title:"Ops!!",
                    short:d?.error,
                    error:true
                });
            } else {
                let arr = d?.students;

                delete arr.pass
                delete arr.salt

                setvalues(arr);
                setstats(d?.stats);
                setavg(d?.avg);
            }

            setploader(false);
        }, { _id:  open?.student});
        

    }, [])
    

    if (ploader) {
        return (<Loader />);
    }

    return (

        <>
        
        <Popups setOpen={setopen} style={{maxWidth:600, borderTop:"4px solid #fd745a", maxHeight:"96vh"}}>
            {loader && <Loader />}
           <>

                <div className='popupBody interface data quizSend' >

                    <div className='dataHead' style={{height:60}}>
                        <nav >
                            <a onClick={() => setminiTabs(0)} className={miniTabs == 0 ? 'checked' : "none"}>Student info</a>
                            <a onClick={() => setminiTabs(1)} className={miniTabs == 1 ? 'checked' : "none"}>Statistics</a>
                        
                        </nav>
                    </div>

                    <form onSubmit={submit}>
                        

                        <div className={`tabsSection ${miniTabs == 0 && "active"}`}>

                            <Input 
                                title="Student Name" 
                                name="taskname"
                                type="text"
                                validate={true}
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    name:v,
                                }))}
                                value={values?.name}
                            />

                            <Input 
                                title="Class" 
                                name="class"
                                type="select"
                                validate={true}
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    classes:v,
                                }))}
                                values={myClasses}
                                value={values?.classes}
                            />

                            <Input 
                                title="Student #ID" 
                                name="zehot"
                                type="text"
                                validate={true}
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    zehot:v,
                                }))}
                                value={values?.zehot}
                            />


                            <Input 
                                title="Status" 
                                name="class"
                                type="select"
                                validate={true}
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    status:v,
                                }))}
                                values={myStatus}
                                value={values?.status}
                            />


                            <Input 
                                title="New Password" 
                                name="password"
                                type="password"
                                // validate={true}
                                onChange={(v) => setvalues(p => ({
                                    ...p,
                                    pass:v,
                                }))}
                                
                            />

                                    
                            <div style={{display:"flex", gap:5}}>
                                <button  className='btn btnQuizSend'>Save Changes</button>
                            </div>
                            
                        </div>


                        <div className={`tabsSection ${miniTabs == 1 && "active"}`}>
                            
                            <div className='statsBox'>
                                <div>
                                    <span>Total tasks</span>
                                    <b>{stats?.length}</b>
                                </div>

                                <div>
                                    <span>Score average</span>
                                    <b>{avg}%</b>
                                </div>
                            </div>

                            <div style={{padding:0}} className='quizzesList'>
								<table>
									<thead>
										<tr>
											<td>#</td>
											<td style={{width:"30%"}} className='align-left'>Name</td>
											<td >Date</td>
											<td >Status</td>
											<td>Scrore</td>
											<td>Timer</td>
										</tr>
									</thead>
									
									<tbody>
                                        {stats?.map((v, k) => {
                                            return (
                                                <tr key={k}>
                                                    <td>{k + 1}</td>
                                                    <td className='align-left'>{v?.task?.name}</td>
                                                    <td >{dateToText(v?.time)}</td>
                                                    <td >{v?.finished ? <span className='tag green'>Finished</span> : <span className='tag orange'>Waiting</span>}</td>
                                                    <td>{v?.score ? <span className='tag'>{v?.score}%</span> : "--"}</td>
                                                    <td>{v?.timer || "--"}</td>
                                                </tr>
                                            );
                                        })}
                                        
										
									</tbody>
								</table>
							</div>

                        </div>




                    </form>
                    

                    
                </div>
            </> 

            

        </Popups>
        </>
    );
})