import { useEffect, useState, useContext, useRef} from 'react'
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import './css/Profile.css'
import { AppContext } from "../Context";
import Fetch, { Loader, dateToText } from "../inc/Fetch";
import SendTask from "./popups/SendTask";
import { Img } from "../components/Elements";
import { Input, MultiSelect, ImageUpload } from "../components/Form";

import Footer from "./Footer";
import Header from "./Header";

import { RiQuestionAnswerLine } from "react-icons/ri";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { MdDeleteOutline, MdEdit, MdOutlineGroup } from "react-icons/md";

export default function Cp() {
	const { t } = useTranslation();
	const {success, userinfo, languageText} = useContext(AppContext);
	const [open, setopen] = success;
	const [logedIn, setlogedIn] = userinfo;
	const [text, settext] = languageText;

	const [loader, setloader] = useState(true);
	const [loader2, setloader2] = useState(false);
	const [values, setvalues] = useState({});
	
	const [myClasses, setmyClasses] = useState([]);

	function submit(e) {
		e.preventDefault();
        setloader2(true);

		Fetch("moeUsers/update", (d) => {

            if (d?.error) {
                setopen({
                    
                    short:t("error",  { returnObjects: true })[d?.error],
                    error:true
                });
            } else {  
                
				setopen({
                    short:text?.info_updated,
                    error:false
                });
				setlogedIn(p => ({
					...p,
					userinfo: { ...p?.userinfo, ...d?.success },
				}))
                
            }

            setloader2(false);
        }, values);
	}


	useEffect(() => {

		if (!logedIn?.userinfo) {

			return false;
		}
		setvalues(logedIn?.userinfo);

			let Cls = [];
            Object.entries(global.Classes)?.map(v => {

                Object.entries(global.Grades)?.map(v2 => {

                    Cls.push({name: `${v[1]} (${v2[1]})`, val:`${v[0]}-${v2[0]}`});
    
                });

            });

            setmyClasses(Cls);

			setTimeout(() => {
				setloader(false)
			}, 100);
	}, []);

	if (loader) {
		return (<Loader />)
	}


	return (
		
		<>
			
			<Header title={text?.profile_title}/>
			{loader2 && <Loader />}

			<form onSubmit={submit} className='loginForm profileBox'>
				<h2>{text?.update_your_profile}</h2>


					<ImageUpload 
                            title="" 
                            onChange={(v) => setvalues(p => ({
                                ...p,
                                img:v,
                            }))}
                            
                            value={'img/150x150' + values?.img}
                    />

					<Input 
						title={text?.fullname} 
						name="name"
						type="text"
						validate={true}
						onChange={(v) => setvalues(p => ({
							...p,
							name:v,
						}))}
						value={values?.name}
					/>

					<Input 
						title={text?.address}
						name="address"
						type="text"
						
						onChange={(v) => setvalues(p => ({
							...p,
							city:v,
						}))}
						value={values?.city}
					/>

					<Input 
						title={text?.yourID}
						name="zehot"
						type="text"
						validate={true}
						onChange={(v) => setvalues(p => ({
							...p,
							zehot:v,
						}))}
						value={values?.zehot}
					/>

					{logedIn?.userinfo?.userlevel >= 3 &&
					<MultiSelect 
						title={text?.classes}
						name="classes"
						type="select"
						validate={true}
						
						onChange={(v) => setvalues(p => ({
							...p,
							classes:v,
						}))}
						values={myClasses}
						value={values?.classes}
					/>}

						<div className='or'>
							<span>{text?.login_info}</span>
						</div>

					<Input 
						title={text?.username}
						name="name"
						type="text"
						validate={true}
						disabled={true}
						onChange={(v) => setvalues(p => ({
							...p,
							user:v,
						}))}
						value={values?.user}
					/>

					<Input 
						title={text?.current_pass}
						name="cpass"
						type="password"
						onChange={(v) => setvalues(p => ({
							...p,
							cpass:v,
						}))}
					/>

					<Input 
						title={text?.new_pass}
						name="pass"
						type="password"
						// validate={true}
						onChange={(v) => setvalues(p => ({
							...p,
							pass:v,
						}))}
					/>

					<Input 
						title={text?.confirm_pass} 
						name="pass2"
						type="password"
						onChange={(v) => setvalues(p => ({
							...p,
							pass2:v,
						}))}
						// value={values?.name}
					/>

					<label className="formInputBox focused">
						<button className='btn'>{text?.update_profile}</button>
					</label>
					

				
			</form>

			<Footer />
		</>
	)
}
